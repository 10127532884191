import React, { useMemo } from 'react'
import { useTween } from '../3d/hooks/useTween'
import PlanetPrototype from '../3d/components/PlanetPrototyp'

interface Props {
  planetPosition: 0 | 1 | 2 | 3 | 4 | 5
  mousePositionX: number // mouse position , X axis in % of the page
  boolean: number // mouse position , Y axis in % of the page
  animateCube: false
  onCubeAnimationEnd?: () => void
}

const Planet: React.FC<Props> = ({
  planetPosition,
  mousePositionX = 0,
  mousePositionY = 0,
}) => {
  const rotationValue = useMemo(() => {
    return (Math.PI / 5) * planetPosition
  }, [planetPosition])

  const mouseRotations = useMemo(
    () => ({ x: mousePositionX / Math.PI, y: mousePositionY / Math.PI }),
    [mousePositionX, mousePositionY]
  )

  const rotation = useTween({
    duration: 500,
    easing: 'easeInOutCubic',
    value: rotationValue,
  })

  return (
    <>
      <PlanetPrototype
        position={[0, 180, 0]}
        rotation={[mouseRotations.y, 0, rotation + mouseRotations.x]}
      />
    </>
  )
}

export default Planet
