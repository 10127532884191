import React, { useContext } from 'react'
import { Link, graphql } from 'gatsby'
import styled from 'styled-components'
import LocalizedLink from './LocalizedLink'
import { PageContext } from './Layout'
import { Colors } from '../styles'

const Wrapper = styled.header`
  margin-bottom: 24px;
  background: ${Colors.accent};
`

const Content = styled.div`
  display: flex;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 1.45rem 1.0875rem;
`

const Nav = styled.nav`
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;

  > * {
    margin-left: 8px;
  }
`

const LocaleSwitcher = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  line-height: 1;
  font-size: 14px;
`
interface HeaderProps {
  data: any
  siteTitle: string
}

const Header: React.FC<HeaderProps> = ({ siteTitle = '', data = {} }) => {
  const { locale, name } = useContext(PageContext)
  console.log('rerender header')
  return (
    <Wrapper>
      <Content>
        <h1 style={{ margin: 0 }}>
          <LocalizedLink to="/">{siteTitle}</LocalizedLink>
        </h1>
        <Nav>
          <LocalizedLink to="/agency">{data.agency}</LocalizedLink>
          <LocalizedLink to="/studio">{data.studio}</LocalizedLink>
          <LocalizedLink to="/team">{data.team}</LocalizedLink>
          <LocalizedLink to="/portfolio">{data.portfolio}</LocalizedLink>
          <LocalizedLink to="/careers">{data.careers}</LocalizedLink>
          <LocalizedLink to="/contact">{data.contact}</LocalizedLink>
        </Nav>
        <LocaleSwitcher>
          {locale === 'fr-fr' ? (
            <Link hrefLang="en-us" to={`/en/${name}`}>
              en
            </Link>
          ) : (
            <Link hrefLang="fr-fr" to={`/${name}`}>
              fr
            </Link>
          )}
        </LocaleSwitcher>
      </Content>
    </Wrapper>
  )
}

export default Header

export const query = graphql`
  fragment HeaderQuery on PrismicHeader {
    data {
      agency
      careers
      contact
      portfolio
      studio
      team
    }
  }
`
