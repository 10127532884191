import React from 'react'
import FBXModel, { FBXModelProps } from './loaders/FBXLoader'
// TODO remove that
// eslint-disable-next-line @typescript-eslint/no-var-requires
const PlanetPrototypeModel = require('../assets/PlanetPrototyp_01.fbx')

export type PlanetPrototypeProps = Omit<FBXModelProps, 'url'>

const PlanetPrototype: React.FC<PlanetPrototypeProps> = ({
  animation,
  ...props
}) => FBXModel({ url: PlanetPrototypeModel, animation, ...props })

export default PlanetPrototype
