import React, { useEffect, useMemo, useState } from 'react'
import { GroupWithAnimation } from '../../types'
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader'
import useAnimationMixer from '../../hooks/useAnimationMixer'
import { Group } from 'three'

export type FBXModelProps = Partial<Group> & {
  url: string
  animation?: string
}

const FBXModel: React.FC<FBXModelProps> = ({ url, animation, ...props }) => {
  const [obj, set] = useState<GroupWithAnimation>()

  const { getAnimationByName, playAnimation } = useAnimationMixer(obj)

  const animationObject = useMemo(() => {
    if (animation) {
      return getAnimationByName(animation)
    }
  }, [animation, getAnimationByName])

  useEffect(() => {
    if (animationObject) {
      playAnimation(animationObject)
    }
  }, [animationObject, playAnimation])

  useMemo(() => new FBXLoader().load(url, set), [url])

  return obj ? <primitive object={obj} {...props} /> : null
}

export default FBXModel
