import React, { useEffect } from 'react'
import useStats from '../hooks/useStats'

export interface PerfStats {
  fps: number
}

interface Props {
  onStatsUpdate?: (stats: PerfStats) => void
}

const Stats: React.FC<Props> = ({ onStatsUpdate }) => {
  const frames = useStats()

  useEffect(() => {
    if (onStatsUpdate) {
      onStatsUpdate({
        fps: frames,
      })
    }
  }, [frames])
  return null
}

export default Stats
