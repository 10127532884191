import { GroupWithAnimation } from '../types'
import { useCallback, useMemo } from 'react'
import { AnimationMixer, AnimationClip } from 'three'
import { useFrame } from 'react-three-fiber'

const useAnimationMixer = (obj?: GroupWithAnimation) => {
  const mixer = useMemo(() => (obj ? new AnimationMixer(obj) : null), [obj])

  useFrame(() => {
    if (mixer) {
      mixer.setTime(Date.now() / 1000)
    }
  })

  const playAnimation = useCallback(
    animation => {
      if (mixer) {
        mixer.clipAction(animation).play()
      }
    },
    [mixer]
  )

  const getAnimationByName = useCallback(
    animation => {
      if (animation && obj) {
        if (!obj.animations) return null
        return AnimationClip.findByName(obj.animations, animation)
      }
    },
    [obj]
  )

  return {
    mixer,
    animations: obj ? obj.animations : [],
    playAnimation,
    getAnimationByName,
  }
}

export default useAnimationMixer
