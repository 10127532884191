import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { Colors } from '../styles'

const Wrapper = styled.footer`
  margin-top: 24px;
  background: ${Colors.dark};
`

const Content = styled.div`
  display: flex;
  align-items: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 1.45rem 1.0875rem;
  color: white;
`

interface FooterProps {
  data: { footer: string }
}

const Footer: React.FC<FooterProps> = ({ data = { footer: '' } }) => {
  return (
    <Wrapper>
      <Content>{data.footer}</Content>
    </Wrapper>
  )
}

export default Footer

export const query = graphql`
  fragment FooterQuery on PrismicFooter {
    data {
      footer
    }
  }
`
