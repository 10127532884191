import { useEffect, useState } from 'react'
import { useFrame } from 'react-three-fiber'
import { ease, easingName } from '../easing'

type useTweenFunction = (data: {
  value: number
  duration: number
  easing?: easingName
  loop?: boolean
}) => number

export const useTween: useTweenFunction = ({
  value,
  duration,
  easing = 'linear',
  loop = false,
}) => {
  const [startTime, setStartTime] = useState(Date.now())
  const [currentValue, setCurrentValue] = useState(value)
  const [startValue, setStartValue] = useState(value)

  useEffect(() => {
    setStartValue(currentValue)
    setStartTime(Date.now())
  }, [value])
  // eslint-disable-next-line sonarjs/cognitive-complexity
  useFrame(() => {
    if (currentValue != value) {
      const deltaT = Math.abs(Date.now() - startTime)
      const ratioT = ease(deltaT / duration, easing)
      const deltaV = Math.abs(startValue - value)
      const v = deltaV * ratioT

      setCurrentValue(() => {
        if (Date.now() - startTime >= duration) {
          if (loop) {
            setStartTime(Date.now())
            setCurrentValue(startValue)
          }
          return value
        }
        if (startValue < value) {
          if (v > value) {
            if (loop) {
              setCurrentValue(startValue)
              setStartTime(Date.now())
            }
            return value
          }
          return startValue + v
        } else if (startValue > value) {
          if (v > startValue - value) return value
          return startValue - v
        }
      })
    }
  })

  return currentValue
}
