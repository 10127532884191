import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { PageContext } from './Layout'
import locales from '../../config/i18n'

interface LocalizedLinkProps {
  to: string
}

const LocalizedLink: React.FC<LocalizedLinkProps> = ({ to, ...props }) => {
  const { locale } = useContext(PageContext)
  const isIndex = to === '/'
  const path = locales[locale].isDefault
    ? to
    : `${locales[locale].path}${isIndex ? '' : `${to}`}`

  return <Link {...props} to={path} />
}

export default LocalizedLink

LocalizedLink.propTypes = {
  to: PropTypes.string.isRequired,
}
