import React, { useMemo } from 'react'
import { WEBGL } from '../utils/webgl'

/**
 * Origin: https://threejs.org/docs/#manual/en/introduction/WebGL-compatibility-check
 */

interface Props {
  fallback: React.Component
}

const WebGLFallback: React.FC<Props> = ({ fallback, children }) => {
  const canUseWebGL = useMemo(() => {
    return WEBGL.isWebGLAvailable()
  }, [])
  if (!canUseWebGL) {
    return fallback
  }
  return <>{children}</>
}

export default WebGLFallback
