/*
 * Layout component is the highest component of page
 * Sub component is passed in parameter "children"
 *
 * */
import * as React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import Header from './Header'
import Footer from './Footer'
import { Colors } from '../styles'
import './layout.css'
import PlanetBanner from './PlanetBanner'
import { Span2 } from '@lonestone/ui-kit/dist/index'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-family: sans-serif;
`

const Content = styled.main`
  flex-grow: 1;
  width: 100%;
  padding: 0 1.0875rem 1.45rem;
  margin: 0 auto;
  color: ${Colors.dark};
`

export const PageContext = React.createContext({ locale: 'fr-fr', name: '' })

interface LayoutProps {
  children: React.ReactNode
  pageContext: {
    locale: string
    name: string
  }
  headerData: any
  footerData: any
}

const Layout: React.FC<LayoutProps> = ({
  children,
  pageContext = { locale: 'fr-fr', name: '' },
  headerData,
  footerData,
}) => {
  // useStaticQuery is required for retrieve data from non page component
  // here we get title from config
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  console.log('rerender layout')

  return (
    <PageContext.Provider value={pageContext}>
      <Wrapper>
        <Header data={headerData} siteTitle={data.site.siteMetadata.title} />
        <PlanetBanner />
        <Span2 txt={'test'}></Span2>
        <Content>{children}</Content>
        <Footer data={footerData} />
      </Wrapper>
    </PageContext.Provider>
  )
}

export default Layout
