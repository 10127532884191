import React, { useMemo, useState } from 'react'
import Planet from './Planet'
import { Canvas } from 'react-three-fiber'
import Stats, { PerfStats } from '../3d/components/stats'
import { useMousePosition } from '../hooks/useMousePosition'
import WebGLFallback from '../3d/components/WebGLFallback'

const PlanetBanner: React.FC = () => {
  const [stats, setStats] = useState<PerfStats>({
    fps: 0,
  })
  if (typeof window === 'undefined') {
    return <></>
  }

  const location = useMemo(() => window.location.pathname, [
    // TODO : warning, window is not defined during server rendering
    window.location.pathname,
  ])

  const mousePosition = useMousePosition()

  const rotationValue = useMemo(() => {
    switch (location) {
      case '/agency':
        return 0
      case '/studio':
        return 1
      case '/team':
        return 2
      case '/portfolio':
        return 3
      case '/careers':
        return 4
      case '/contact':
        return 5
    }
    return 0
  }, [location])

  return (
    <div>
      <div>FPS:{stats.fps}</div>
      <WebGLFallback fallback={<div>fallback</div>}>
        <Canvas camera={{ fov: 10, position: [0, -40, 0] }}>
          <Stats onStatsUpdate={s => setStats(s)} />
          <ambientLight />
          <pointLight position={[10, 0, 10]} />
          <Planet
            planetPosition={rotationValue}
            mousePositionX={mousePosition.x / window.innerWidth}
            mousePositionY={mousePosition.y / window.innerHeight}
          />
        </Canvas>
      </WebGLFallback>
    </div>
  )
}

export default PlanetBanner
