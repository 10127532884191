import { useEffect, useState } from 'react'
import { useFrame } from 'react-three-fiber'

const useStats = () => {
  const [frames, setFrames] = useState(0)
  const [lastFrames, setLastFrames] = useState(0)
  const [lastReset, setLastReset] = useState(Date.now())
  useFrame(() => {
    setFrames(frames + 1)
  })

  useEffect(() => {
    if (Date.now() - lastReset > 1000) {
      setLastFrames(frames)
      setFrames(0)
      setLastReset(Date.now())
    }
  }, [frames])

  return lastFrames
}
export default useStats
