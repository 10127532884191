module.exports = {
  'fr-fr': {
    isDefault: true,
    path: 'fr',
    locale: 'fr-fr',
    siteLanguage: 'fr',
    ogLang: 'fr_FR',
  },
  'en-us': {
    path: 'en',
    locale: 'en-us',
    siteLanguage: 'en',
    ogLang: 'en_US',
  },
}
